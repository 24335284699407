import { logConfig } from "./config";

export const getLogger = (prefixArray) => {
  const textPrefixs =
    prefixArray.map((prefix) => getPrefixText(prefix)).join("") + "%c";
  const prefixStyles = prefixArray.map((prefix) => getPrefixStyle(prefix));
  const textStyle = "padding-left: 0.3rem;";

  const logger = {
    log: (text) => {
      console.log(textPrefixs + text, ...prefixStyles, textStyle);
    },
    error: (text) => {
      console.error(textPrefixs + text, ...prefixStyles, textStyle);
    },
    warn: (text) => {
      console.warn(textPrefixs + text, ...prefixStyles, textStyle);
    },
  };
  return logger;
};

function getPrefixStyle(prefix) {
  const config = logConfig[prefix];
  return `background: ${config.background}; color: ${config.color}; padding-right: 0.3rem; padding-left: 0.3rem;`;
}

function getPrefixText(prefix) {
  const config = logConfig[prefix];
  return `%c${config.text}`;
}
