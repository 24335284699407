import { set } from "idb-keyval";
import { best_host_key, default_best_host } from "./constants";
import isValidHost from "./isValidHost";
import { logger } from "./logger";

export default async function (host) {
  const best_host = isValidHost(host) ? host : default_best_host;
  await set(best_host_key, best_host);
  logger.log(`Setting Binary Host to ${best_host}`);
}
