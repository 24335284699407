<template>
  <div id="app">
    <NavBar />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { testBestHost } from "@/utils/binary-host";
import { version } from "@/utils/constants";
import isElectron from "@/utils/isElectron";
import NavBar from "@/components/Misc/NabBar";
import { getLogger } from "@/utils/log"
import { B2Hosts } from "@/utils/objectStorage/constants";

const logger = getLogger(["app"])

const preconnectLinks = B2Hosts.map(host => ( { rel: 'preconnect', href: new URL(host).origin, crossorigin: "anonymous" }))

export default {
  name: "app",
  metaInfo: {
    title: "在线 DOS 游戏",
    titleTemplate: "%s - 在线 DOS 游戏",
    link: [
      ...preconnectLinks,
    ]
  },
  components: {
    NavBar
  },
  beforeCreate: function () {
    testBestHost();
    if ("serviceWorker" in navigator && !isElectron) {
      window.addEventListener("load", function () {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then((reg) => logger.log("Service Worker registered!", reg))
          .catch((err) =>
            logger.error("Failed to register Service Worker!", err)
          );
      });
    }
  },
  created: function () {
    logger.log(`Frontend Version: ${version}`);
  },
};
</script>
