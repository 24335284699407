<template>
  <b-navbar
    toggleable="lg"
    :sticky="true"
    class="navbar-expand-lg"
    :class="navbarClass"
  >
    <router-link class="navbar-brand" :to="{ name: 'index' }">🎮</router-link>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <li class="nav-item">
          <router-link class="nav-item nav-link" :to="{ name: 'index' }">
            首页
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-item nav-link" :to="{ name: 'games' }">
            游戏列表
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-item nav-link" :to="{ name: 'trending' }">
            热门
          </router-link>
        </li>
        <b-nav-item-dropdown text="更多" right>
          <router-link class="dropdown-item" :to="{ name: 'custom' }">
            自定义游戏<b-badge class="ml-1" variant="secondary">Beta</b-badge>
          </router-link>
          <a href="https://flash.zczc.cz" class="dropdown-item">
            ⚡ Flash 保存计划
          </a>
          <router-link class="dropdown-item" :to="{ name: 'wikiBackend' }">
            引擎对比
          </router-link>
          <router-link class="dropdown-item" :to="{ name: 'desktopDownload' }">
            桌面版
          </router-link>
          <router-link class="dropdown-item" :to="{ name: 'about' }">
            关于
          </router-link>
          <router-link class="dropdown-item" :to="{ name: 'settings' }">
            设置
          </router-link>
        </b-nav-item-dropdown>
        <b-nav-text>
          <a href="https://flash.zczc.cz/">
            ⚡ Flash 保存计划，欢迎大家尝试分享！
          </a>
        </b-nav-text>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form class="my-2 my-lg-0" method="get" action="/search">
          <b-form-input
            class="mr-sm-2"
            placeholder="游戏名称"
            type="search"
            name="q"
            v-model="search_query"
          ></b-form-input>
          <b-button
            :variant="searchButtonVariant"
            class="my-2 my-sm-0"
            type="submit"
            @click.stop.prevent="submitClick()"
            >搜索</b-button
          >
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { BBadge } from "bootstrap-vue";

export default {
  name: "NavBar",
  components: {
    BBadge,
  },
  data() {
    return {
      search_query: this.$route.query.q,
      darkTheme:
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches,
    };
  },
  mounted: function () {
    if (window.matchMedia) {
      // to support old browser
      const MediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");

      // TODO: some wired stuff
      MediaQueryList.addEventListener("change", () => {
        console.log("prefers-color-scheme: dark changed");
        this.darkTheme =
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches;
      });
    }
  },
  computed: {
    navbarClass() {
      return this.darkTheme
        ? ["navbar-dark", "bg-dark"]
        : ["navbar-light", "bg-light"];
    },
    searchButtonVariant() {
      return this.darkTheme ? "secondary" : "outline-success";
    },
  },
  methods: {
    submitClick() {
      this.$router
        .push({ name: "search", query: { q: this.search_query } })
        .catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // But print any other errors to the console
            console.error(err);
          }
        });
    },
  },
};
</script>
