import isValidHost from "./isValidHost";

export default async function (host) {
  if (!isValidHost(host)) {
    throw new Error(`Invalid host: ${host}`);
  }

  const start = Date.now();
  const res = await fetch(`${host}/test.json`, { cache: "no-cache" });
  const json = await res.json();

  if (json.success) {
    const latency = Date.now() - start;
    return latency;
  } else {
    throw new Error(`Failed to fetch ${host}: Not success json`);
  }
}
