// Game Binary URL
export const game_binaries_host_labels = {
  "https://b2.zczc.cz/file/dos-bin": "Cloudflare",
  "https://do-sfo2-float.dos-bin.zczc.cz": "DigitalOcean (SFO2 Float)",
  "https://do-sfo2.dos-bin.zczc.cz": "DigitalOcean (SFO2)",
  "https://do-sfo3-float.dos-bin.zczc.cz": "DigitalOcean (SFO3 Float)",
  "https://do-sfo3.dos-bin.zczc.cz": "DigitalOcean (SFO3)",
}

export const game_binaries_hosts = Object.keys(game_binaries_host_labels);

export const default_best_host = "https://b2.zczc.cz/file/dos-bin";
export const default_sw_host = "https://dos-bin.zczc.cz";
export const best_host_key = "game_binaries_best_host";
export const enable_host_autoselect_key = "enable_host_autoselect";
export const cloudflare_host = "https://b2.zczc.cz"
