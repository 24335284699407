import { game_binaries_hosts, default_best_host } from "./constants";

import getLatency from "./getLatency";
import isValidHost from "./isValidHost";
import setBestHost from "./setBestHost";
import getAutoTest from "./getAutoTest";

import isChina from "@/utils/isChina";

import { logger } from "./logger";

export default async function (forceTest=false) {
  // disable binary auto test
  if (!(await getAutoTest())) {
    logger.log("Binary Auto Test: disabled.");
    return;
  }

  try {
    if ((!isChina) && (!forceTest)) {
      // if not in China, throw Error and use default
      throw new Error("Not in China, use the default host");
    }

    const generatePromise = async (host) => ({
      host,
      latency: await getLatency(host),
    });
    const promises = game_binaries_hosts.map(generatePromise);
    const { host, latency } = await Promise.any(promises);

    if (!isValidHost(host)) {
      throw new Error(`Invalid host: ${host}`);
    }

    logger.log(`Best Host: ${host}, latency: ${latency}`);
    await setBestHost(host);
  } catch (e) {
    logger.log(`Fallback Host: ${default_best_host}, ${e}`);
    await setBestHost(default_best_host);
  }
}
