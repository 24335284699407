export const B2HostsLabels = {
  "https://b2.zczc.cz/file/": "Cloudflare",
  "https://cloudcone-la.b2.zczc.men/file/": "CloudCone #1 (LA)",
  "https://cloudcone-la2.b2.zczc.men/file/": "CloudCone #2 (LA)",
  "https://mlnl-hk-nat0.b2.zczc.men:21212/file/": "MLNL (HK NAT0)",
  "https://cloudcone-la-ipv6.b2.zczc.men/file/": "CloudCone #1 (LA IPv6)",
  "https://cloudcone-la2-ipv6.b2.zczc.men/file/": "CloudCone #2 (LA IPv6)",
  "https://mlnl-hk-ipv6.b2.zczc.men/file/": "MLNL (HK IPv6)",
  // "https://do-sfo2-float.b2.zczc.cz/file/": "DigitalOcean (SFO2 Float)",
  // "https://do-sfo2.b2.zczc.cz/file/": "DigitalOcean (SFO2)",
  // "https://do-sfo3-float.b2.zczc.cz/file/": "DigitalOcean (SFO3 Float)",
  // "https://do-sfo3.b2.zczc.cz/file/": "DigitalOcean (SFO3)",
};

export const B2Hosts = Object.keys(B2HostsLabels);

export const OriginalB2Host = "https://b2.zczc.cz/file/";
