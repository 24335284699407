import Vue from 'vue'
import Router from 'vue-router'

// import index from '@/views/index.vue'

const index = () => import(/* webpackChunkName: "index" */ /* webpackPrefetch: true */ '@/views/index')

const game = () => import(/* webpackChunkName: "game" */ /* webpackPrefetch: true */ '@/views/game')
const custom = () => import(/* webpackChunkName: "game" */ '@/views/custom')

const info = () => import(/* webpackChunkName: "game_info" */ '@/views/info')
const download = () => import(/* webpackChunkName: "game_info" */ '@/views/download')

const error = () => import(/* webpackChunkName: "others" */ '@/views/error')
const about = () => import(/* webpackChunkName: "others" */ '@/views/about')
const wikiBackend = () => import(/* webpackChunkName: "others" */ '@/views/wiki/backend')

const games = () => import(/* webpackChunkName: "games" */ /* webpackPrefetch: true */ '@/views/games')

const trending = () => import(/* webpackChunkName: "trending" */ '@/views/trending')

const search = () => import(/* webpackChunkName: "search" */ '@/views/search')

const settings = () => import(/* webpackChunkName: "settings" */ '@/views/settings')

Vue.use(Router)

export default new Router({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'index',
      component: index
    },
    {
      path: '/about',
      name: 'about',
      component: about
    },
    {
      path: '/error',
      name: 'error',
      component: error
    },
    {
      path: '/games/custom',
      redirect: '/games/custom/',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/games/custom/',
      name: 'custom',
      component: custom,
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/games/:identifier/download',
      name: 'download',
      component: download
    },
    {
      path: '/games/:identifier/info',
      name: 'info',
      component: info
    },
    {
      path: '/games/:identifier',
      redirect: '/games/:identifier/',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/games/:identifier/',
      name: 'game',
      component: game,
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/games',
      redirect: '/games/',
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/games/',
      name: 'games',
      component: games,
      pathToRegexpOptions: { strict: true }
    },
    {
      path: '/search',
      name: 'search',
      component: search
    },
    {
      path: '/trending',
      name: 'trending',
      component: trending
    },
    {
      path: '/wiki/backend',
      name: 'wikiBackend',
      component: wikiBackend
    },
    {
      path: '/settings',
      name: 'settings',
      component: settings
    },
    {
      path: '/desktop/download',
      beforeEnter() {
        window.location.replace("https://dos.zczc.cz/desktop/download");
      },
      name: "desktopDownload"
    },
    {
      path: '*',
      component: error
    }
  ],
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})
