// No Ads
export const no_ads = [
  "金瓶梅之偷情宝鉴",
  "金瓶梅",
  "女皇杯(日)",
  "麻雀幻想曲3",
];

// Frontend Version
export { version } from "../../package.json";

// Index Page Max Size
export const index_max_size = 50;

// Trending Page Max Size
export const trending_max_size = 10;

export const assetsCDNPath = `/`;
