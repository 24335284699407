export const logConfig = {
    webTorrent: {
        background: "#A2E4B8",
        color: "#333",
        text: "🧲 WebTorrent"
    },
    binary: {
        background: "#000",
        color: "#fff",
        text: "📦 Binary"
    },
    objectStorage: {
        background: "#000",
        color: "#fff",
        text: "📦 Object Storage"
    },
    cache: {
        background: "#FFFF99",
        color: "#333",
        text: "⚡ Cache"
    },
    backend: {
        background: "#E0E0E0",
        color: "#000",
        text: "⚙️ Backend"
    },
    jsdos: {
        background: "#dcd0ff",
        color: "#222",
        text: "💻 jsdos"
    },
    emularity: {
        background: "#dcd0ff",
        color: "#222",
        text: "💻 Emularity"
    },
    save: {
        background: "#CC0000",
        color: "#fff",
        text: "💾 Save"
    },
    app: {
        background: "#228B22",
        color: "#fff",
        text: "✨ App"
    },
    desktop: {
        background: "#dcd0ff",
        color: "#222",
        text: "💻 Desktop"
    },
}