import Vue from "vue";
import App from "./App";
import router from "./router";
// import { BootstrapVue } from 'bootstrap-vue'
import {
  LayoutPlugin,
  AlertPlugin,
  CardPlugin,
  SpinnerPlugin,
  FormInputPlugin,
  ButtonPlugin,
  NavbarPlugin,
  ToastPlugin,
} from "bootstrap-vue";
import { VueMasonryPlugin } from "vue-masonry";
import VueMeta from "vue-meta";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "@/styles/bootstrap_main.scss";
import "@/styles/bootstrap_dark.scss";

Vue.use(VueMeta);
// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Make BootstrapVue Plugins available throughout your project
Vue.use(LayoutPlugin);
Vue.use(AlertPlugin);
Vue.use(CardPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormInputPlugin);
Vue.use(ButtonPlugin);
Vue.use(NavbarPlugin);
Vue.use(ToastPlugin);
// Vue MasonryPlugin
Vue.use(VueMasonryPlugin);

Vue.config.productionTip = false;

import Ads from 'vue-google-adsense'

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-7592189052095678', isNewAdsCode: true })

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
});
